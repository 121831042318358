import React from 'react';

const Insta = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25_5563)">
        <g clipPath="url(#clip1_25_5563)">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.1654 3.67188H20.9572C24.3372 3.67188 27.0772 6.4118 27.0772 9.79167V19.5833C27.0772 22.9632 24.3372 25.7031 20.9572 25.7031H11.1654C7.7854 25.7031 5.04541 22.9632 5.04541 19.5833V9.79167C5.04541 6.4118 7.7854 3.67188 11.1654 3.67188ZM21.8808 10.0957C22.5567 10.0957 23.1047 9.54776 23.1047 8.87178C23.1047 8.19582 22.5567 7.64783 21.8808 7.64783C21.2047 7.64783 20.6567 8.19582 20.6567 8.87178C20.6567 9.54776 21.2047 10.0957 21.8808 10.0957ZM16.0626 9.1797C13.0206 9.1797 10.5546 11.6456 10.5546 14.6875C10.5546 17.7294 13.0206 20.1953 16.0626 20.1953C19.1045 20.1953 21.5706 17.7294 21.5706 14.6875C21.5738 13.2258 20.9945 11.8229 19.961 10.7893C18.9272 9.75569 17.5244 9.17644 16.0626 9.1797ZM12.6965 14.6856C12.6965 16.5446 14.2035 18.0515 16.0625 18.0515C17.9214 18.0515 19.4284 16.5446 19.4284 14.6856C19.4284 12.8267 17.9214 11.3197 16.0625 11.3197C14.2035 11.3197 12.6965 12.8267 12.6965 14.6856Z" fill="#F78900"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_25_5563">
          <rect width="30.8906" height="29.4062" fill="white"/>
        </clipPath>
        <clipPath id="clip1_25_5563">
          <rect width="30.875" height="29.375" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default Insta;
