import React from 'react';

const FaceBook = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25_5571)">
        <g clipPath="url(#clip1_25_5571)">
          <path d="M26.9239 6.1198V23.2552C26.9239 24.6071 25.8279 25.7031 24.4759 25.7031H19.8859C19.5479 25.7031 19.274 25.4292 19.274 25.0911V17.7473H22.1381C22.429 17.7475 22.6797 17.5428 22.7378 17.2578L23.1785 15.0547C23.1967 14.9657 23.1744 14.8731 23.1177 14.8022C23.0608 14.7314 22.9755 14.6893 22.8847 14.6875H19.274V10.7096C19.274 10.5406 19.411 10.4036 19.58 10.4036H22.6399C22.978 10.4036 23.2519 10.1297 23.2519 9.79167V7.95573C23.2519 7.61775 22.978 7.34375 22.6399 7.34375H19.58C17.552 7.34375 15.908 8.98771 15.908 11.0156V14.6875H14.4025C14.0645 14.6875 13.7905 14.9614 13.7905 15.2995V17.1355C13.7905 17.4734 14.0645 17.7473 14.4025 17.7473H15.908V25.0911C15.908 25.4292 15.6341 25.7031 15.2959 25.7031H7.34007C5.98808 25.7031 4.89209 24.6071 4.89209 23.2552V6.1198C4.89209 4.76784 5.98808 3.67188 7.34007 3.67188H24.4759C25.8279 3.67188 26.9239 4.76784 26.9239 6.1198Z" fill="#F78900"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_25_5571">
          <rect width="30.8906" height="29.4062" fill="white"/>
        </clipPath>
        <clipPath id="clip1_25_5571">
          <rect width="30.875" height="29.375" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default FaceBook;
