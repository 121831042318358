import React from 'react';

const FeatureOne = () => {
  return (
    <svg width="96" height="95" viewBox="0 0 96 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_25_5416)">
        <circle cx="48.2124" cy="43.5" r="43.5" fill="white"/>
      </g>
      <path d="M26.4624 43.5001C26.4624 38.1017 27.5891 36.9751 32.9874 36.9751H37.3374C42.7358 36.9751 43.8624 38.1017 43.8624 43.5001C43.8624 48.8984 42.7358 50.0251 37.3374 50.0251H32.9874C27.5891 50.0251 26.4624 48.8984 26.4624 43.5001ZM52.5624 32.6251C52.5624 27.2267 53.6891 26.1001 59.0874 26.1001H63.4374C68.8358 26.1001 69.9624 27.2267 69.9624 32.6251C69.9624 38.0234 68.8358 39.1501 63.4374 39.1501H59.0874C53.6891 39.1501 52.5624 38.0234 52.5624 32.6251ZM52.5624 54.3751C52.5624 48.9767 53.6891 47.8501 59.0874 47.8501H63.4374C68.8358 47.8501 69.9624 48.9767 69.9624 54.3751C69.9624 59.7734 68.8358 60.9001 63.4374 60.9001H59.0874C53.6891 60.9001 52.5624 59.7734 52.5624 54.3751ZM52.5624 32.6251L46.0374 43.5001L52.5624 54.3751" fill="white"/>
      <path d="M52.5624 32.6251C52.5624 27.2267 53.6891 26.1001 59.0874 26.1001H63.4374C68.8358 26.1001 69.9624 27.2267 69.9624 32.6251C69.9624 38.0234 68.8358 39.1501 63.4374 39.1501H59.0874C53.6891 39.1501 52.5624 38.0234 52.5624 32.6251ZM52.5624 32.6251L46.0374 43.5001L52.5624 54.3751M52.5624 54.3751C52.5624 48.9767 53.6891 47.8501 59.0874 47.8501H63.4374C68.8358 47.8501 69.9624 48.9767 69.9624 54.3751C69.9624 59.7734 68.8358 60.9001 63.4374 60.9001H59.0874C53.6891 60.9001 52.5624 59.7734 52.5624 54.3751ZM26.4624 43.5001C26.4624 38.1017 27.5891 36.9751 32.9874 36.9751H37.3374C42.7358 36.9751 43.8624 38.1017 43.8624 43.5001C43.8624 48.8984 42.7358 50.0251 37.3374 50.0251H32.9874C27.5891 50.0251 26.4624 48.8984 26.4624 43.5001Z" stroke="#03C7FD" strokeWidth="2.71875" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
        <filter id="filter0_d_25_5416" x="0.712402" y="0" width="95" height="95" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_25_5416"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_25_5416" result="shape"/>
        </filter>
      </defs>
    </svg>

  );
};

export default FeatureOne;
