import React from 'react';

const Lineone = () => {
  return (
    <svg width="141" height="161" viewBox="0 0 141 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.93388 160.082C-1.50117 126.998 -7.98948 63.3727 49.5376 73.5461C121.446 86.2629 104.254 118.006 96.0304 127.515C90.0613 134.416 76.4997 136.3 69.6845 105.493C62.8602 74.645 61.0059 -16.4016 140.044 5.30991" stroke="#FCA61F" strokeOpacity="0.4" strokeWidth="2.22651"/>
    </svg>

  );
};

export default Lineone;
