import React from "react";
import styles from "./useCases.module.scss";
import MainLayout from './layout/mainLayout';
import { Col, Container, Row } from "react-bootstrap";
import SideImage1 from "../../src/assets/images/image 6.png";
import SideImage2 from "../../src/assets/images/professor.png";
import SideImage3 from "../../src/assets/images/school-teacher.png";
import FillDots from "../assets/icons/fillDots";
import GreenDots from "../assets/icons/greenDots";
import Lineone from "../assets/icons/lineone";
import Tick from '../assets/icons/tick';

const UseCases = () => {
  return (
    <MainLayout>
      <Container className={`${styles.wrapper} py-5`}>
        {/* <div className={styles.useCasesWrapper}>
          <h1>Use Cases</h1>
        </div> */}
        <Row>
          <Col xl="6" data-aos="fade-up" data-aos-duration="500">
            <div className={styles.SideImage1}>
              <span className={styles.fillDots}>
                <FillDots color={"#473BF0"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#68D585"} />
              </span>
              <span className={styles.line}>
                <Lineone />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage1} alt="img" />
            </div>
          </Col>
          <Col className="ps-md-5 pt-md-5" style={{marginTop:'-30px'}}>
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>Company</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="550">
              <p className="h2">Corporate Trainer</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{
                  marginTop: "10px",
                  textIndent: "20px",
                  textAlign: "justify"
                }}
              >
                Meet <strong>David</strong> - The Corporate Learning Specialist<br/>
                As a Training Manager at a fast-growing tech company, David is responsible for keeping 500+ employees skilled and up-to-date. With offices across three time zones, his challenge isn&apos;t just creating content—it&apos;s scaling quality training efficiently.
              </p>
            </Row>
            <p
              className="fw-medium lh-lg fs-5"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                textAlign: "justify",
                marginTop: "20px"
              }}
            >
              Before:
              <p className='h6 mt-3'><Tick/><span className='ms-3'>3 weeks to create training videos</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Endless SME scheduling conflicts</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>$3,000+ per video production</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Outdated content by launch day</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Inconsistent quality across regions</span></p>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}

            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                The Pain:
              </p>
              <p className="fw-medium lh-lg fs-6" style={{textIndent:"20px"}}>
                With 500+ employees across time zones, our video bottleneck meant critical knowledge wasn&apos;t reaching teams when they needed it.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Now:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Training videos created in minutes</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>One-take recordings from SMEs</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Instant updates when processes change</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Consistent quality globally</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Zero production costs</span></p>
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Impact:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>85% faster video creation</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>30% shorter onboarding time</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>300% more training content</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>90% cost reduction</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Zero outdated materials</span></p>
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                David&apos;s Reality Today:
              </p>
              <p className="fw-medium lh-lg fs-6" style={{textIndent:"20px"}}>
                When processes change, we update training instantly. What took a month now takes hours. Every department creates professional videos on-demand.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Key Wins:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Product updates → Instant training</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Sales enablement → Same-day delivery</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>IT processes → Quick how-to guides</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>HR onboarding → Consistent globally</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Support team → Real-time best practices</span></p>
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            className="ps-xl-5 pt-xl-5 ps-lg-5 pt-lg-5 ps-md-5 ps-4"
            data-aos="zoom-in-right"
            data-aos-duration="600"
          >
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>Content</p>
            </Row>
            <p className="h3" data-aos="fade-up" data-aos-duration="550">
              Course creator
            </p>
            <p
              className="fw-medium lh-lg fs-6"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                marginTop: "10px",
                textIndent: "20px",
                textAlign: "justify"
              }}
            >
              Meet <strong>Sarah</strong> - The Aspiring Course Creator <br/> Sarah is an experienced marketing consultant with 10+ years of expertise in social media strategy. She knows her stuff and clients love her one-on-one sessions.<br/> But here&apos;s her struggle:<br/>🔹 Her Reality Today: I have so much knowledge to share, but creating course videos is killing my momentum. Last week, I spent:</p>
            <p className='h6 mt-3'><Tick/><span className='ms-3'>6 hours scripting two course modules</span></p>
            <p className='h6 mt-3'><Tick/><span className='ms-3'>4 hours recording and re-recording to get it &apos;perfect&apos;</span></p>
            <p className='h6 mt-3'><Tick/><span className='ms-3'>8 hours editing and fixing minor mistakes</span></p>
            <p className='h6 mt-3'><Tick/><span className='ms-3'>2 hours fighting with video editing software</span></p>
            <p
              className="fw-medium lh-lg fs-6"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                textAlign: "justify",
                marginTop: "20px"
              }}
            >
              And still, the videos don&apos;t feel as engaging as my live sessions.
            </p>
            <p
              className="fw-medium lh-lg fs-5"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                textAlign: "justify",
                marginTop: "20px"
              }}
            >
              💡 Sarah&apos;s Breaking Point:
            </p>
          </Col>
          <Col xl="6" style={{marginTop: "70px"}}>
            <div
              className={styles.SideImage2}
              data-aos="zoom-in"
              data-aos-duration="500"
            >
              <span className={styles.fillDots}>
                <FillDots color={"#FDBA09"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#3E66F3"} />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage2} alt="img" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}

            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify", textIndent: "20px", marginTop:"-40px" }}
            >
              <p className="fw-medium lh-lg fs-6">
                I could be helping hundreds of businesses with my knowledge. Instead, I&apos;m stuck playing video editor, scriptwriter, and production manager. My course launch is months behind schedule, and I&apos;m losing potential students every day.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-5">
              ✨ Sarah&apos;s Transformation With Us:
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify", textIndent: "20px" }}
            >
              <p className="fw-medium lh-lg fs-6">
                Now, I just explain concepts naturally - like I do with my clients. The platform turns my expertise into professional videos instantly:
              </p>
            </Row>
            <Row>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Created 12 course modules in two days</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>No more scripting - just natural conversations</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Zero time wasted on editing</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Professional quality that matches my expertise</span></p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify", textIndent: "20px" }}
            >
              <p className="fw-medium lh-lg fs-6">
                &quot;Best part? My students say these videos feel more personal and engaging than traditionally edited ones.&quot;
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-5">
                📈 The Impact:
              </p>
            </Row>
            <Row>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Launched her course 8 weeks earlier than planned</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Created 45+ videos in the time it used to take to make 5</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>Student engagement increased by 80%</span></p>
              <p className='h6 mt-3'><Tick/><span className='ms-3'>More time focusing on what matters - teaching and connecting with students</span></p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify", marginTop:"10px" }}
            >
              <p className="fw-medium lh-lg fs-5">
                Sarah&apos;s Advice:
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify", textIndent: "20px" }}
            >
              <p className="fw-medium lh-lg fs-6">
                &quot;Stop letting video creation hold your expertise hostage. I went from dreading course creation to actually enjoying it. Now, my knowledge flows naturally into professional videos, just like my in-person sessions.&quot;</p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="6" data-aos="fade-up" data-aos-duration="500" style={{marginTop: "70px"}}>
            <div className={styles.SideImage3}>
              <span className={styles.fillDots}>
                <FillDots color={"#473BF0"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#68D585"} />
              </span>
              <span className={styles.line}>
                <Lineone />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage3} alt="img" />
            </div>
          </Col>
          <Col className="ps-md-5 pt-md-5">
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>Company</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="550">
              <p className="h2">Product Manager</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{
                  marginTop: "10px",
                  textIndent: "20px",
                  textAlign: "justify"
                }}
              >
                Meet <strong>Lisa</strong>- The Product Manager Racing Against Time <br/> As a Product Manager at a B2B SaaS company, Lisa leads a team shipping new features every two weeks. Her biggest challenge? Ensuring customers and internal teams actually understand and use these features.
              </p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Before:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Features shipped, but adoption lagged weeks behind</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>5+ meetings to align on feature demos</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>7+ days to create each product video</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Inconsistent messaging across teams</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>$2,000+ per video production</span></p>
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Before:
              </p>
              <p className="fw-medium lh-lg fs-6">
                We were building great features, but our communication bottleneck meant users never saw their full value. Our latest release took 2 weeks just to explain what we built in 3 days.
              </p>
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Now:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Features shipped, but adoption lagged weeks behind</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>5+ meetings to align on feature demos</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>7+ days to create each product video</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>Inconsistent messaging across teams</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>$2,000+ per video production</span></p>
              </p>
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Results:
                <p className='h6 mt-3'><Tick/><span className='ms-3'>65% increase in feature adoption</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>40% fewer support tickets</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>90% faster demo creation</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>50% shorter customer onboarding</span></p>
                <p className='h6 mt-3'><Tick/><span className='ms-3'>$0 video production costs</span></p>
              </p>
              <p
                className="fw-medium lh-lg fs-5"
                data-aos="fade-up"
                data-aos-duration="600"
                style={{
                  textAlign: "justify",
                  marginTop: "20px"
                }}
              >
                Lisa&apos;s Reality Today:
                <p className="fw-medium lh-lg fs-6">
                  <strong>Morning:</strong> Deploy feature <br/><strong>Afternoon:</strong> Share video explanation <br/><strong>Next Day:</strong> Users actively engaging
                </p>
                <p className="fw-medium lh-lg fs-6">
                  &quot;From &apos;launching and hoping&apos; to &apos;launching and knowing&apos; our features will be understood and used.&quot;
                </p>
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default UseCases;







