import React from 'react';

const RightArrow = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 7L9 13M15 7L9 1M15 7L1 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default RightArrow;
