import React from 'react';

const Quotation = () => {
  return (
    <svg width="96" height="76" viewBox="0 0 96 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.8" d="M87 0.19999L95.1 8C85.7 19.2 81 29.8 81 39.8C81 43.4 82.6 47.2 85.8 51.2C89 55 90.6 58.8 90.6 62.6C90.6 66.2 89 69.3 85.8 71.9C82.8 74.5 79 75.8 74.4 75.8C69.8 75.8 65.9 73.9 62.7 70.1C59.5 66.1 57.9 60.1 57.9 52.1C57.9 43.9 60.3 35.1 65.1 25.7C70.1 16.1 77.4 7.59999 87 0.19999ZM29.7 0.19999L37.8 8C28.4 19.2 23.7 29.8 23.7 39.8C23.7 43.4 25.3 47.2 28.5 51.2C31.7 55 33.3 58.8 33.3 62.6C33.3 66.2 31.7 69.3 28.5 71.9C25.5 74.5 21.7 75.8 17.1 75.8C12.5 75.8 8.6 73.9 5.4 70.1C2.2 66.1 0.600001 60.1 0.600001 52.1C0.600001 43.9 3 35.1 7.8 25.7C12.8 16.1 20.1 7.59999 29.7 0.19999Z" fill="#F78900"/>
    </svg>

  );
};

export default Quotation;
