import React from 'react';

const Tick = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_25_5276" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
        <path d="M11 21C12.3135 21.0017 13.6143 20.7438 14.8278 20.2411C16.0413 19.7385 17.1435 19.001 18.071 18.071C19.001 17.1435 19.7385 16.0413 20.2411 14.8278C20.7438 13.6143 21.0017 12.3135 21 11C21.0017 9.68654 20.7438 8.38569 20.2411 7.17222C19.7385 5.95874 19.001 4.85656 18.071 3.92901C17.1435 2.99902 16.0413 2.26151 14.8278 1.75885C13.6143 1.25619 12.3135 0.998307 11 1.00001C9.68654 0.998334 8.38569 1.25623 7.17222 1.75889C5.95874 2.26154 4.85656 2.99904 3.92901 3.92901C2.99904 4.85656 2.26154 5.95874 1.75889 7.17222C1.25623 8.38569 0.998334 9.68654 1.00001 11C0.998307 12.3135 1.25619 13.6143 1.75885 14.8278C2.26151 16.0413 2.99902 17.1435 3.92901 18.071C4.85656 19.001 5.95874 19.7385 7.17222 20.2411C8.38569 20.7438 9.68654 21.0017 11 21Z" fill="white" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M7 11L10 14L16 8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </mask>
      <g mask="url(#mask0_25_5276)">
        <path d="M-1 -1H23V23H-1V-1Z" fill="#F78900"/>
      </g>
    </svg>

  );
};

export default Tick;
