import React from 'react';

const ProcessLine = () => {
  return (
    <svg width="886" height="329" viewBox="0 0 886 329" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.89331 256.917C44.412 284.947 143.445 337.802 199.428 324.989C269.406 308.972 286.68 226.484 394.748 207.264C502.816 188.044 582.096 254.915 665.804 158.412C749.513 61.9102 774.641 -28.0477 884.107 12.2575" stroke="#8976FD" strokeWidth="3.21528" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ProcessLine;
