import React from 'react';

const Input = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="36" cy="36" r="36" fill="#F78900"/>
      <path d="M28.4734 23.0014C26.9107 23.2586 25.7832 23.7375 24.9034 24.627C23 26.5522 23 29.6515 23 35.8502C23 42.0488 23 45.1481 24.9034 47.0747C26.8067 49.0014 29.8705 49 35.9993 49C42.1268 49 45.1919 49 47.0953 47.0747C48.9986 45.1495 48.9986 42.0488 48.9986 35.8502C48.9986 29.6515 48.9986 26.5509 47.0953 24.6256C46.2154 23.7362 45.0879 23.2572 43.5252 23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.5784 26.4223C33.2517 25.73 35.0415 23.0015 35.9993 23.0015M35.9993 23.0015C36.9572 23.0015 38.747 25.73 39.4202 26.4223M35.9993 23.0015V33.9483M48.9986 38.0533H42.2581C41.106 38.0533 40.2015 39.0166 39.6925 40.0333C39.141 41.1389 38.0368 42.1584 35.9993 42.1584C33.9618 42.1584 32.8576 41.1389 32.3048 40.0333C31.7985 39.0166 30.8926 38.0533 29.7405 38.0533H23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Input;
