import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureOne from '../../assets/icons/featureOne';
import FeatureFour from '../../assets/icons/featureFour';
import FeatureThree from '../../assets/icons/featureThree';
import FeatureTwo from '../../assets/icons/featureTwo';
import styles from './features.modules.scss';

const Features = () => {
  return (
    <div className={styles.wrapper}>

   
      <Container>
        <p className='text-center h2 pt-5'>Key Features</p>
        <p className='text-center h6'>Fully managed E2E video and course creation</p>
        <Row className='mt-5'>
          <Col className=' d-flex flex-column align-items-center justify-content-center' xl="3" md="6">
            <div className=''>
              <FeatureOne/>
            </div>
            <p className='text-center pt-2 fw-semibold'>Video layout</p>

          </Col>
          <Col className=' d-flex flex-column align-items-center justify-content-center' xl="3" md="6">
            <div className=''>
              <FeatureTwo/>
            </div>
            <p className='text-center pt-2 fw-semibold'>Video script</p>

          </Col>
          <Col className=' d-flex flex-column align-items-center justify-content-center' xl="3" md="6">
            <div className=''>
              <FeatureThree/>
            </div>
            <p className='text-center pt-2 fw-semibold'>Course structure</p>

          </Col>
          <Col className=' d-flex flex-column align-items-center justify-content-center' xl="3" md="6">
            <div className=''>
              <FeatureFour/>
            </div>
            <p className='text-center pt-2 fw-semibold'>Human like voiceovers</p>

          </Col>
       
        
        </Row>
      </Container>
    </div>
  );
};

export default Features;
