import React from 'react';

const CardIconThree = () => {
  return (
    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0549 7.70216C21.0549 4.55359 18.0431 2 14.3291 2C10.6151 2 7.60327 4.55359 7.60327 7.70216V18.0034C7.60327 21.152 10.6151 23.7055 14.3291 23.7055C18.0431 23.7055 21.0549 21.152 21.0549 18.0034V7.70216Z" stroke="#11D292" strokeWidth="2.27889" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.00024 16.5342C2.00024 18.1531 2.31912 19.7563 2.93867 21.252C3.55822 22.7477 4.46631 24.1068 5.61109 25.2515C6.75587 26.3963 8.11492 27.3044 9.61065 27.924C11.1064 28.5435 12.7095 28.8624 14.3284 28.8624M14.3284 28.8624C15.9474 28.8624 17.5505 28.5435 19.0462 27.924C20.542 27.3044 21.901 26.3963 23.0458 25.2515C24.1906 24.1068 25.0987 22.7477 25.7182 21.252C26.3378 19.7563 26.6566 18.1531 26.6566 16.5342M14.3284 28.8624V36.1832M7.60263 36.1832H21.0543" stroke="#11D292" strokeWidth="2.27889" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default CardIconThree;
