import React from 'react';

const YouTube = () => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25_5575)">
        <g clipPath="url(#clip1_25_5575)">
          <path fillRule="evenodd" clipRule="evenodd" d="M23.6096 5.31199L25.3967 5.49558C27.5123 5.73471 29.1009 7.54048 29.0686 9.66929V19.7058C29.1009 21.8346 27.5123 23.6403 25.3967 23.8795L23.683 24.0631C18.3362 24.6137 12.9468 24.6137 7.59985 24.0631L5.81284 23.8795C3.69718 23.6403 2.10849 21.8346 2.14087 19.7058V9.66929C2.10849 7.54048 3.69718 5.73471 5.81284 5.49558L7.52641 5.31199C12.8733 4.76124 18.2628 4.76124 23.6096 5.31199ZM14.5154 18.6286L19.2767 15.4464H19.3501C19.6067 15.2793 19.7615 14.9937 19.7615 14.6875C19.7615 14.3813 19.6067 14.0958 19.3501 13.9286L14.5888 10.7463C14.3077 10.5563 13.9447 10.5369 13.6449 10.6958C13.3451 10.8546 13.1574 11.1659 13.1568 11.5052V17.8698C13.1726 18.1856 13.3498 18.4711 13.626 18.6254C13.9021 18.7796 14.2381 18.7809 14.5154 18.6286Z" fill="#F78900"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_25_5575">
          <rect width="30.8906" height="29.4062" fill="white" transform="translate(0.5)"/>
        </clipPath>
        <clipPath id="clip1_25_5575">
          <rect width="30.875" height="29.375" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default YouTube;
