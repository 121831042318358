import React from "react";
import MainLayout from "./layout/mainLayout";
import { Typography, Layout, Divider } from "antd";

const { Title, Paragraph, Link } = Typography;
const { Content } = Layout;

const TermsOfUse = () => {
  return (
    <MainLayout>
      <Layout style={{ backgroundColor: "#fff", padding: "24px" }}>
        `
        <Content>
          <Typography>
            <Title level={2}>Terms of Use</Title>
            {/* <Text strong>Effective Date:</Text> <Text>[Insert Date]</Text> */}
            <Divider />
            <Title level={3}>1. Introduction</Title>
            <Paragraph>
              Welcome to Feedio.ai. By accessing our services, you agree to
              these Terms of Use.
            </Paragraph>
            <Divider />
            <Title level={3}>2. Use of Services</Title>
            <Paragraph>
              You must be at least 18 years old or have parental consent to use
              our services. You agree to provide accurate information when
              creating an account.
            </Paragraph>
            <Divider />
            <Title level={3}>3. User Content</Title>
            <Paragraph>
              You retain ownership of your content but grant us a license to
              use, display, and modify your content for service provision.
            </Paragraph>
            <Divider />
            <Title level={3}>4. Prohibited Activities</Title>
            <Paragraph>Users may not:</Paragraph>
            <ul>
              <li>Engage in unauthorized data scraping or data mining.</li>
              <li>Upload any harmful or illegal content.</li>
              <li>Attempt to disrupt our services or website.</li>
            </ul>
            <Divider />
            <Title level={3}>5. Intellectual Property</Title>
            <Paragraph>
              All Feedio.ai branding, logos, and trademarks are the property of
              Feedio.ai. Unauthorized use is prohibited.
            </Paragraph>
            <Divider />
            <Title level={3}>6. Limitation of Liability</Title>
            <Paragraph>
              Feedio.ai is not liable for any indirect, incidental, or
              consequential damages arising from your use of the service.
            </Paragraph>
            <Divider />
            <Title level={3}>7. Governing Law</Title>
            <Paragraph>
              These Terms are governed by India/TamilNadu law.
            </Paragraph>
            <Divider />
            <Title level={3}>8. Contact Us</Title>
            <Paragraph>
              For questions, contact us at{" "}
              <Link href="mailto:info@techjedi.in">info@techjedi.in</Link>.
            </Paragraph>
          </Typography>
        </Content>
      </Layout>
      `
    </MainLayout>
  );
};

export default TermsOfUse;
