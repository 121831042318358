import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Tick from '../../assets/icons/tick';
// import RightArrow from '../../assets/icons/rightArrow';
import styles from './price.module.scss';
import { toast } from 'react-toastify';

const Price = () => {
  const [isModal, setModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e?.preventDefault();
    toast.success("Form Submitted!");
    setModalOpen(false);
  };

  const FormModal = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="name" className="col-form-label" label="Name">
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your name"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="phoneNo" className="col-form-label" label="Phone number">
                <Form.Control
                  type="number"
                  name="phoneNo"
                  id="phoneNo"
                  placeholder="Your Phone number"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="email" className="col-form-label" label="Email">
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your email"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="message" className="col-form-label" label="Message">
                <Form.Control
                  as="textarea"
                  name="message"
                  id="message"
                  placeholder="Message"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
          </Form>
          <div className='d-flex justify-content-end gap-2'>
            <Button className={`${styles.cusbtnOne} mb-3`} onClick={props.onHide} variant=''>Close</Button>
            <Button className={`${styles.cusbtn} mb-3`} variant='' onClick={handleSubmit}>Submit</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const FreePlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Free</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50' > $0 Free for everyone </span>
          </Card.Title>
          <hr className="border-color " />
          {/* <Card.Text className='pt-3'><span className='me-2'><Tick /></span></Card.Text> */}
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'></span>Video Generation:</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>10 minutes video generations</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>9:16, 16:9 aspect ratios</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Videos up to 30s</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>2 Avatars with voiceover</Card.Text>
          <Card.Text style={{ marginLeft: '30px', marginBottom:'40px' }}><span className='me-2'><Tick /></span>Exports with watermark</Card.Text>
          {/* <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn}`} variant='' href='https://app.feedio.ai/'>Free trial<span className='ms-2'><RightArrow /></span></Button>
          </div> */}
        </Card.Body>
      </Card>
    );
  };

  const PremiumPlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Pro</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50'> $10 / month </span>
          </Card.Title>
          <hr className="border-color " />
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'></span>Everything in Free, plus:</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>20 minutes video generations</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Videos up to 10 minutes</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>25 Avatars with voiceover</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Export with custom watermark</Card.Text>
          {/* <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn1}`} variant=''>Subscribe<span className='ms-2'><RightArrow /></span></Button>
          </div> */}
        </Card.Body>
      </Card>
    );
  };

  const EnterprisePlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Enterprise</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50'>Contact sales</span>
          </Card.Title>
          <hr className="border-color " />
          {/* <Card.Text className='pt-3'><span className='me-2'><Tick /></span>Price: $35 per month or $350 <b>annually</b></Card.Text> */}
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'></span>Everything in Pro, plus:</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Custom Avatar</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Dedicated Support</Card.Text>
          <Card.Text style={{ marginLeft: '30px' }}><span className='me-2'><Tick /></span>Audit logs</Card.Text>
          {/* <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn1}`} variant=''>Contact<span className='ms-2'><RightArrow /></span></Button>
          </div> */}
        </Card.Body>
      </Card>
    );
  };

  const FAQSection = () => (
    <Accordion>
      <Accordion.Item eventKey="0" style={{marginTop:'50px'}}>
        <Accordion.Header>What is FeedIO and how does it work?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          Dr. James McQuivey claims that a one-minute video is equivalent to 1.8 million words, highlighting the effectiveness of video as a communication medium. Recognizing the advancements in Generative AI for text and images, we felt the need for similar capabilities in video. As a result, we have developed FeedIO, an AI-powered video platform that can generate and edit videos.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What should I use FeedIO for?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          1. Creating explainer videos for YouTube channels.<br/>
          2. Producing promotional videos for services or products.<br/>
          3. Developing how-to videos to explain the usage of a product.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How long does it take to generate a video?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          The time required to generate a video depends on the length of the storyboard. For every minute of video, the generation process typically takes around half a minute.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How much does it cost to use?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          Cost: $1 per minute of video generated.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Is there a free trial or demo available?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          FeedIO offers a free trial with credits to generate 10 minutes of video content for users to test the platform.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Can I use the generated videos for commercial purposes?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          Yes, the generated videos can be used for commercial purposes, provided that attribution is given stating the videos were created using GenAI (Generative AI) technology, either in whole or in part.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>How secure is my data during the video generation process?</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#F2F2F2', paddingLeft:'35px'}}>
          We prioritizes data security during the video generation process through encryption, strict access controls, data segregation, hosting on secure infrastructure, regular security audits and compliance with regulations like GDPR. Source data is deleted after video generation unless explicitly requested to retain it. While no system is risk-free, we continuously enhance security measures to provide robust data protection.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  return (
    <div className={styles.wrapper}>
      <Container>
        <p className='h1 text-center pt-3' data-aos="fade-up" data-aos-duration="700">Pricing</p>
        <p className='h6 text-center py-4' data-aos="fade-up" data-aos-duration="780">
          Our pricing structure is designed to be transparent and flexible, ensuring affordability while catering to diverse needs and budgets.
        </p>
        <Row>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <FreePlanCard />
          </Col>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <PremiumPlanCard />
          </Col>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <EnterprisePlanCard />
          </Col>
        </Row>
        <p className='h6 text-center py-4' data-aos="zoom-in" data-aos-duration="800">Custom solutions for enterprise clients</p>
        <div className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
          <>
            <Button className={`${styles.cusbtn} mb-3`} variant='' onClick={() => setModalOpen(true)}>Contact Us</Button>
            <FormModal show={isModal} onHide={() => setModalOpen(false)} />
          </>
        </div>
        <div style={{paddingBottom:'50px'}}>
          <p className='h1 text-center pt-3' data-aos="fade-up" data-aos-duration="700" style={{marginTop:'50px'}}>Frequently Asked Questions</p>
          <FAQSection />
        </div>
      </Container>
    </div>
  );
};

export default Price;
