import React from 'react';

const CardIconTwo = () => {
  return (
    <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0H34.5C36.4891 0 38.3968 0.790176 39.8033 2.1967C41.2098 3.60322 42 5.51088 42 7.5V22.5C42 24.4891 41.2098 26.3968 39.8033 27.8033C38.3968 29.2098 36.4891 30 34.5 30H7.5C5.51088 30 3.60322 29.2098 2.1967 27.8033C0.790176 26.3968 0 24.4891 0 22.5V7.5ZM7.5 3C6.30653 3 5.16193 3.47411 4.31802 4.31802C3.47411 5.16193 3 6.30653 3 7.5V22.5C3 23.6935 3.47411 24.8381 4.31802 25.682C5.16193 26.5259 6.30653 27 7.5 27H34.5C35.6935 27 36.8381 26.5259 37.682 25.682C38.5259 24.8381 39 23.6935 39 22.5V7.5C39 6.30653 38.5259 5.16193 37.682 4.31802C36.8381 3.47411 35.6935 3 34.5 3H7.5ZM6 16.5C6 16.1022 6.15804 15.7206 6.43934 15.4393C6.72065 15.158 7.10218 15 7.5 15H22.5C22.8978 15 23.2794 15.158 23.5607 15.4393C23.842 15.7206 24 16.1022 24 16.5C24 16.8978 23.842 17.2794 23.5607 17.5607C23.2794 17.842 22.8978 18 22.5 18H7.5C7.10218 18 6.72065 17.842 6.43934 17.5607C6.15804 17.2794 6 16.8978 6 16.5ZM7.5 21C7.10218 21 6.72065 21.158 6.43934 21.4393C6.15804 21.7206 6 22.1022 6 22.5C6 22.8978 6.15804 23.2794 6.43934 23.5607C6.72065 23.842 7.10218 24 7.5 24H16.5C16.8978 24 17.2794 23.842 17.5607 23.5607C17.842 23.2794 18 22.8978 18 22.5C18 22.1022 17.842 21.7206 17.5607 21.4393C17.2794 21.158 16.8978 21 16.5 21H7.5ZM27 16.5C27 16.1022 27.158 15.7206 27.4393 15.4393C27.7206 15.158 28.1022 15 28.5 15H34.5C34.8978 15 35.2794 15.158 35.5607 15.4393C35.842 15.7206 36 16.1022 36 16.5C36 16.8978 35.842 17.2794 35.5607 17.5607C35.2794 17.842 34.8978 18 34.5 18H28.5C28.1022 18 27.7206 17.842 27.4393 17.5607C27.158 17.2794 27 16.8978 27 16.5ZM22.5 21C22.1022 21 21.7206 21.158 21.4393 21.4393C21.158 21.7206 21 22.1022 21 22.5C21 22.8978 21.158 23.2794 21.4393 23.5607C21.7206 23.842 22.1022 24 22.5 24H34.5C34.8978 24 35.2794 23.842 35.5607 23.5607C35.842 23.2794 36 22.8978 36 22.5C36 22.1022 35.842 21.7206 35.5607 21.4393C35.2794 21.158 34.8978 21 34.5 21H22.5Z" fill="#299DDD"/>
    </svg>


  );
};

export default CardIconTwo;
