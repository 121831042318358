import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './process.module.scss';
import ProcessLine from '../../assets/icons/processLine';
import Input from '../../assets/icons/input';
import AiProcess from '../../assets/icons/aiprocess';
import Human from '../../assets/icons/human';
import Output from '../../assets/icons/output';

const Process = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <p className={`${styles.subTitle} h5`} data-aos="fade-up">The Process</p>
        <p className={`${styles.title} h2`} data-aos="fade-up">How it works</p>
        <p className={styles.subPara} data-aos="fade-up">Our process seamlessly combines AI recommended content with human oversight to transform an idea into polished, high-quality video content.</p>
        <div className={styles.processLine}>
          <span className={styles.processSvg} data-aos="zoom-in" data-aos-duration="700"><ProcessLine/></span>
          <div>

            <span className={`${styles.numberOne} fw-semibold`} data-aos="zoom-in" data-aos-duration="800">1</span>
            <span className={`${styles.numberTwo} fw-semibold`} data-aos="zoom-in" data-aos-duration="900">2</span>
            <span className={`${styles.numberThree} fw-semibold`} data-aos="zoom-in" data-aos-duration="1000">3</span>
            <span className={`${styles.numberFour} fw-semibold`} data-aos="zoom-in" data-aos-duration="1100">4</span>
           
            <span className={styles.one} data-aos="zoom-in" data-aos-duration="900"><Input/></span>
            <span className={styles.two}><AiProcess/></span>
            <span className={styles.three}><Human/></span>
            <span className={styles.four}><Output/></span>

            <div className={styles.paraOne} data-aos="zoom-in" data-aos-duration="1000">
              <p className='fw-medium'>Input</p>
              <p>User starts with a topic and provide supporting images for video.</p>
            </div>

            <div className={styles.paraTwo} data-aos="zoom-in" data-aos-duration="1100">
              <p className='fw-medium'>AI Processing</p>
              <p>Gen-AI models are used to analyze and enhance content.</p>
            </div>

            <div className={styles.paraThree} data-aos="zoom-in" data-aos-duration="1200">
              <p className='fw-medium'>Human Review</p>
              <p>Users ensure quality and accuracy of the recommended content.</p>
            </div>

            <div className={styles.paraFour} data-aos="zoom-in" data-aos-duration="1300">
              <p className='fw-medium'>Final Output</p>
              <p>Deliver polished, professional videos ready for distribution.</p>
            </div>
            
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Process;
