import React, { useState } from 'react';
import styles from './cookieConsent.module.scss';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(!localStorage.getItem('cookiesAccepted'));

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', true);
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesAccepted', false);
    setIsVisible(false);
  };

  return isVisible ? (
    <div className={styles.cookieConsentBanner}>
      <p className={styles.text}>
        We use cookies to ensure that we provide you with the best experience on our website. Only essential cookies are enabled by default. To learn more about the cookies we use, please see our <a href='/privacy-policy' className={styles.link}>Privacy Policy</a>.
      </p>
      <div className={styles.buttonContainer}>
        <button onClick={handleAccept} className={styles.acceptButton}>Allow all cookies</button>
        <button onClick={handleDecline} className={styles.declineButton}>Do not allow cookies</button>
      </div>
    </div>
  ) : null;
};

export default CookieConsent;
