import { useEffect } from 'react';
import Website from './pages';
import AOS from 'aos';
import 'aos/dist/aos.css';
import UseCases from './pages/UseCases';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  useEffect(()=>{
    AOS.init();
  },[AOS]);

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/use-cases" element={<UseCases />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </Router>
  );
}

export default App;
