import React from "react";
import MainLayout from "./layout/mainLayout";
import { Typography, Layout, Divider } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <Layout style={{ backgroundColor: '#fff', padding: '24px' }}>
        <Content>
          <Typography>
            <Title level={2}>Privacy Policy</Title>
            {/* <Text strong>Effective Date:</Text> <Text>[10-10-2024]</Text> */}
            
            <Divider />
            <Title level={3}>1. Introduction</Title>
            <Paragraph>
              Feedio.ai (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) is committed to protecting your privacy. This Privacy Policy explains how we
              collect, use, and share information when you use our website and services.
            </Paragraph>

            <Divider />
            <Title level={3}>2. Information We Collect</Title>
            <Paragraph>
              <Text strong>Personal Information:</Text> When you sign up, we may collect information such as your name, email
              address, and payment details.
            </Paragraph>
            <Paragraph>
              <Text strong>Usage Data:</Text> We collect information on how you interact with our website, such as IP
              addresses, device information, and browsing activities.
            </Paragraph>
            <Paragraph>
              <Text strong>Cookies:</Text> We use cookies and similar tracking technologies to enhance user experience and
              analyze website usage.
            </Paragraph>

            <Divider />
            <Title level={3}>3. How We Use Your Information</Title>
            <Paragraph>We use your information to:</Paragraph>
            <ul>
              <li>Provide and improve our services.</li>
              <li>Communicate with you regarding updates and support.</li>
              <li>Personalize content and recommendations.</li>
              <li>Ensure security and prevent fraudulent activity.</li>
            </ul>

            <Divider />
            <Title level={3}>4. Sharing of Information</Title>
            <Paragraph>We may share your information:</Paragraph>
            <ul>
              <li>With third-party vendors assisting in delivering our services.</li>
              <li>As required by law, to comply with legal obligations or respond to lawful requests.</li>
            </ul>

            <Divider />
            <Title level={3}>5. Data Security</Title>
            <Paragraph>
              We implement measures to protect your information; however, no transmission method is 100% secure.
            </Paragraph>

            <Divider />
            <Title level={3}>6. Your Choices</Title>
            <Paragraph>
              You may update or delete your account information and adjust cookie preferences.
            </Paragraph>

            <Divider />
            <Title level={3}>7. Contact Us</Title>
            <Paragraph>
              For questions, please contact us at <Link href="mailto:info@techjedi.in">info@techjedi.in</Link>.
            </Paragraph>
          </Typography>
        </Content>
      </Layout>
    </MainLayout>
  );
};

export default PrivacyPolicy;
