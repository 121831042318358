import React from 'react';

const GreenDots = ({color}) => {
  return (
    <svg width="94" height="44" viewBox="0 0 94 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78105 2.8906C4.78105 3.93478 3.93415 4.78113 2.89088 4.78113C1.8469 4.78113 1 3.93478 1 2.8906C1 1.84642 1.8469 1.00008 2.89088 1.00008C3.93415 1.00008 4.78105 1.84642 4.78105 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.044 2.8906C15.044 3.93478 14.1971 4.78113 13.1538 4.78113C12.1098 4.78113 11.2629 3.93478 11.2629 2.8906C11.2629 1.84642 12.1098 1.00008 13.1538 1.00008C14.1971 1.00008 15.044 1.84642 15.044 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7666 2.8906C24.7666 3.93478 23.9197 4.78113 22.8765 4.78113C21.8325 4.78113 20.9856 3.93478 20.9856 2.8906C20.9856 1.84642 21.8325 1.00008 22.8765 1.00008C23.9197 1.00008 24.7666 1.84642 24.7666 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4891 2.8906C34.4891 3.93478 33.6429 4.78113 32.5989 4.78113C31.5549 4.78113 30.708 3.93478 30.708 2.8906C30.708 1.84642 31.5549 1.00008 32.5989 1.00008C33.6429 1.00008 34.4891 1.84642 34.4891 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2122 2.8906C44.2122 3.93478 43.3659 4.78113 42.3217 4.78113C41.2775 4.78113 40.4312 3.93478 40.4312 2.8906C40.4312 1.84642 41.2775 1.00008 42.3217 1.00008C43.3659 1.00008 44.2122 1.84642 44.2122 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.9349 2.8906C53.9349 3.93478 53.0885 4.78113 52.0443 4.78113C51.0002 4.78113 50.1538 3.93478 50.1538 2.8906C50.1538 1.84642 51.0002 1.00008 52.0443 1.00008C53.0885 1.00008 53.9349 1.84642 53.9349 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M63.6573 2.8906C63.6573 3.93478 62.8109 4.78113 61.7675 4.78113C60.7226 4.78113 59.8762 3.93478 59.8762 2.8906C59.8762 1.84642 60.7226 1.00008 61.7675 1.00008C62.8109 1.00008 63.6573 1.84642 63.6573 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.3799 2.8906C73.3799 3.93478 72.533 4.78113 71.4898 4.78113C70.4451 4.78113 69.5989 3.93478 69.5989 2.8906C69.5989 1.84642 70.4451 1.00008 71.4898 1.00008C72.533 1.00008 73.3799 1.84642 73.3799 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83.1026 2.8906C83.1026 3.93478 82.2557 4.78113 81.2124 4.78113C80.1684 4.78113 79.3215 3.93478 79.3215 2.8906C79.3215 1.84642 80.1684 1.00008 81.2124 1.00008C82.2557 1.00008 83.1026 1.84642 83.1026 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.8255 2.8906C92.8255 3.93478 91.9786 4.78113 90.9353 4.78113C89.8913 4.78113 89.0444 3.93478 89.0444 2.8906C89.0444 1.84642 89.8913 1.00008 90.9353 1.00008C91.9786 1.00008 92.8255 1.84642 92.8255 2.8906Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78105 12.6131C4.78105 13.6573 3.93415 14.5037 2.89088 14.5037C1.8469 14.5037 1 13.6573 1 12.6131C1 11.569 1.8469 10.7226 2.89088 10.7226C3.93415 10.7226 4.78105 11.569 4.78105 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.044 12.6131C15.044 13.6573 14.1971 14.5037 13.1538 14.5037C12.1098 14.5037 11.2629 13.6573 11.2629 12.6131C11.2629 11.569 12.1098 10.7226 13.1538 10.7226C14.1971 10.7226 15.044 11.569 15.044 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7666 12.6131C24.7666 13.6573 23.9197 14.5037 22.8765 14.5037C21.8325 14.5037 20.9856 13.6573 20.9856 12.6131C20.9856 11.569 21.8325 10.7226 22.8765 10.7226C23.9197 10.7226 24.7666 11.569 24.7666 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4891 12.6131C34.4891 13.6573 33.6429 14.5037 32.5989 14.5037C31.5549 14.5037 30.708 13.6573 30.708 12.6131C30.708 11.569 31.5549 10.7226 32.5989 10.7226C33.6429 10.7226 34.4891 11.569 34.4891 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2122 12.6131C44.2122 13.6573 43.3659 14.5037 42.3217 14.5037C41.2775 14.5037 40.4312 13.6573 40.4312 12.6131C40.4312 11.569 41.2775 10.7226 42.3217 10.7226C43.3659 10.7226 44.2122 11.569 44.2122 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.9349 12.6131C53.9349 13.6573 53.0885 14.5037 52.0443 14.5037C51.0002 14.5037 50.1538 13.6573 50.1538 12.6131C50.1538 11.569 51.0002 10.7226 52.0443 10.7226C53.0885 10.7226 53.9349 11.569 53.9349 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M63.6573 12.6131C63.6573 13.6573 62.8109 14.5037 61.7675 14.5037C60.7226 14.5037 59.8762 13.6573 59.8762 12.6131C59.8762 11.569 60.7226 10.7226 61.7675 10.7226C62.8109 10.7226 63.6573 11.569 63.6573 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.3799 12.6131C73.3799 13.6573 72.533 14.5037 71.4898 14.5037C70.4451 14.5037 69.5989 13.6573 69.5989 12.6131C69.5989 11.569 70.4451 10.7226 71.4898 10.7226C72.533 10.7226 73.3799 11.569 73.3799 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83.1026 12.6131C83.1026 13.6573 82.2557 14.5037 81.2124 14.5037C80.1684 14.5037 79.3215 13.6573 79.3215 12.6131C79.3215 11.569 80.1684 10.7226 81.2124 10.7226C82.2557 10.7226 83.1026 11.569 83.1026 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.8255 12.6131C92.8255 13.6573 91.9786 14.5037 90.9353 14.5037C89.8913 14.5037 89.0444 13.6573 89.0444 12.6131C89.0444 11.569 89.8913 10.7226 90.9353 10.7226C91.9786 10.7226 92.8255 11.569 92.8255 12.6131Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78105 22.3362C4.78105 23.3803 3.93415 24.2267 2.89088 24.2267C1.8469 24.2267 1 23.3803 1 22.3362C1 21.292 1.8469 20.4456 2.89088 20.4456C3.93415 20.4456 4.78105 21.292 4.78105 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.044 22.3362C15.044 23.3803 14.1971 24.2267 13.1538 24.2267C12.1098 24.2267 11.2629 23.3803 11.2629 22.3362C11.2629 21.292 12.1098 20.4456 13.1538 20.4456C14.1971 20.4456 15.044 21.292 15.044 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7666 22.3362C24.7666 23.3803 23.9197 24.2267 22.8765 24.2267C21.8325 24.2267 20.9856 23.3803 20.9856 22.3362C20.9856 21.292 21.8325 20.4456 22.8765 20.4456C23.9197 20.4456 24.7666 21.292 24.7666 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4891 22.3362C34.4891 23.3803 33.6429 24.2267 32.5989 24.2267C31.5549 24.2267 30.708 23.3803 30.708 22.3362C30.708 21.292 31.5549 20.4456 32.5989 20.4456C33.6429 20.4456 34.4891 21.292 34.4891 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2122 22.3362C44.2122 23.3803 43.3659 24.2267 42.3217 24.2267C41.2775 24.2267 40.4312 23.3803 40.4312 22.3362C40.4312 21.292 41.2775 20.4456 42.3217 20.4456C43.3659 20.4456 44.2122 21.292 44.2122 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.9349 22.3362C53.9349 23.3803 53.0885 24.2267 52.0443 24.2267C51.0002 24.2267 50.1538 23.3803 50.1538 22.3362C50.1538 21.292 51.0002 20.4456 52.0443 20.4456C53.0885 20.4456 53.9349 21.292 53.9349 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M63.6573 22.3362C63.6573 23.3803 62.8109 24.2267 61.7675 24.2267C60.7226 24.2267 59.8762 23.3803 59.8762 22.3362C59.8762 21.292 60.7226 20.4456 61.7675 20.4456C62.8109 20.4456 63.6573 21.292 63.6573 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.3799 22.3362C73.3799 23.3803 72.533 24.2267 71.4898 24.2267C70.4451 24.2267 69.5989 23.3803 69.5989 22.3362C69.5989 21.292 70.4451 20.4456 71.4898 20.4456C72.533 20.4456 73.3799 21.292 73.3799 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83.1026 22.3362C83.1026 23.3803 82.2557 24.2267 81.2124 24.2267C80.1684 24.2267 79.3215 23.3803 79.3215 22.3362C79.3215 21.292 80.1684 20.4456 81.2124 20.4456C82.2557 20.4456 83.1026 21.292 83.1026 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.8255 22.3362C92.8255 23.3803 91.9786 24.2267 90.9353 24.2267C89.8913 24.2267 89.0444 23.3803 89.0444 22.3362C89.0444 21.292 89.8913 20.4456 90.9353 20.4456C91.9786 20.4456 92.8255 21.292 92.8255 22.3362Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78105 32.0588C4.78105 33.103 3.93415 33.9493 2.89088 33.9493C1.8469 33.9493 1 33.103 1 32.0588C1 31.0146 1.8469 30.1683 2.89088 30.1683C3.93415 30.1683 4.78105 31.0146 4.78105 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.044 32.0588C15.044 33.103 14.1971 33.9493 13.1538 33.9493C12.1098 33.9493 11.2629 33.103 11.2629 32.0588C11.2629 31.0146 12.1098 30.1683 13.1538 30.1683C14.1971 30.1683 15.044 31.0146 15.044 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7666 32.0588C24.7666 33.103 23.9197 33.9493 22.8765 33.9493C21.8325 33.9493 20.9856 33.103 20.9856 32.0588C20.9856 31.0146 21.8325 30.1683 22.8765 30.1683C23.9197 30.1683 24.7666 31.0146 24.7666 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4891 32.0588C34.4891 33.103 33.6429 33.9493 32.5989 33.9493C31.5549 33.9493 30.708 33.103 30.708 32.0588C30.708 31.0146 31.5549 30.1683 32.5989 30.1683C33.6429 30.1683 34.4891 31.0146 34.4891 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2122 32.0588C44.2122 33.103 43.3659 33.9493 42.3217 33.9493C41.2775 33.9493 40.4312 33.103 40.4312 32.0588C40.4312 31.0146 41.2775 30.1683 42.3217 30.1683C43.3659 30.1683 44.2122 31.0146 44.2122 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.9349 32.0588C53.9349 33.103 53.0885 33.9493 52.0443 33.9493C51.0002 33.9493 50.1538 33.103 50.1538 32.0588C50.1538 31.0146 51.0002 30.1683 52.0443 30.1683C53.0885 30.1683 53.9349 31.0146 53.9349 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M63.6573 32.0588C63.6573 33.103 62.8109 33.9493 61.7675 33.9493C60.7226 33.9493 59.8762 33.103 59.8762 32.0588C59.8762 31.0146 60.7226 30.1683 61.7675 30.1683C62.8109 30.1683 63.6573 31.0146 63.6573 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.3799 32.0588C73.3799 33.103 72.533 33.9493 71.4898 33.9493C70.4451 33.9493 69.5989 33.103 69.5989 32.0588C69.5989 31.0146 70.4451 30.1683 71.4898 30.1683C72.533 30.1683 73.3799 31.0146 73.3799 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83.1026 32.0588C83.1026 33.103 82.2557 33.9493 81.2124 33.9493C80.1684 33.9493 79.3215 33.103 79.3215 32.0588C79.3215 31.0146 80.1684 30.1683 81.2124 30.1683C82.2557 30.1683 83.1026 31.0146 83.1026 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.8255 32.0588C92.8255 33.103 91.9786 33.9493 90.9353 33.9493C89.8913 33.9493 89.0444 33.103 89.0444 32.0588C89.0444 31.0146 89.8913 30.1683 90.9353 30.1683C91.9786 30.1683 92.8255 31.0146 92.8255 32.0588Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78105 41.7818C4.78105 42.826 3.93415 43.6724 2.89088 43.6724C1.8469 43.6724 1 42.826 1 41.7818C1 40.7377 1.8469 39.8913 2.89088 39.8913C3.93415 39.8913 4.78105 40.7377 4.78105 41.7818Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.044 41.7814C15.044 42.8255 14.1971 43.6719 13.1538 43.6719C12.1098 43.6719 11.2629 42.8255 11.2629 41.7814C11.2629 40.7372 12.1098 39.8908 13.1538 39.8908C14.1971 39.8908 15.044 40.7372 15.044 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7666 41.7814C24.7666 42.8255 23.9197 43.6719 22.8765 43.6719C21.8325 43.6719 20.9856 42.8255 20.9856 41.7814C20.9856 40.7372 21.8325 39.8908 22.8765 39.8908C23.9197 39.8908 24.7666 40.7372 24.7666 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4891 41.7814C34.4891 42.8255 33.6429 43.6719 32.5989 43.6719C31.5549 43.6719 30.708 42.8255 30.708 41.7814C30.708 40.7372 31.5549 39.8908 32.5989 39.8908C33.6429 39.8908 34.4891 40.7372 34.4891 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2122 41.7814C44.2122 42.8255 43.3659 43.6719 42.3217 43.6719C41.2775 43.6719 40.4312 42.8255 40.4312 41.7814C40.4312 40.7372 41.2775 39.8908 42.3217 39.8908C43.3659 39.8908 44.2122 40.7372 44.2122 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.9349 41.7814C53.9349 42.8255 53.0885 43.6719 52.0443 43.6719C51.0002 43.6719 50.1538 42.8255 50.1538 41.7814C50.1538 40.7372 51.0002 39.8908 52.0443 39.8908C53.0885 39.8908 53.9349 40.7372 53.9349 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M63.6573 41.7814C63.6573 42.8255 62.8109 43.6719 61.7675 43.6719C60.7226 43.6719 59.8762 42.8255 59.8762 41.7814C59.8762 40.7372 60.7226 39.8908 61.7675 39.8908C62.8109 39.8908 63.6573 40.7372 63.6573 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.3799 41.7814C73.3799 42.8255 72.533 43.6719 71.4898 43.6719C70.4451 43.6719 69.5989 42.8255 69.5989 41.7814C69.5989 40.7372 70.4451 39.8908 71.4898 39.8908C72.533 39.8908 73.3799 40.7372 73.3799 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M83.1026 41.7814C83.1026 42.8255 82.2557 43.6719 81.2124 43.6719C80.1684 43.6719 79.3215 42.8255 79.3215 41.7814C79.3215 40.7372 80.1684 39.8908 81.2124 39.8908C82.2557 39.8908 83.1026 40.7372 83.1026 41.7814Z" stroke={color} strokeWidth="0.54015"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92.8255 41.7814C92.8255 42.8255 91.9786 43.6719 90.9353 43.6719C89.8913 43.6719 89.0444 42.8255 89.0444 41.7814C89.0444 40.7372 89.8913 39.8908 90.9353 39.8908C91.9786 39.8908 92.8255 40.7372 92.8255 41.7814Z" stroke={color} strokeWidth="0.54015"/>
    </svg>

  );
};

export default GreenDots;
