import React from 'react';

const FeatureTwo = () => {
  return (
    <svg width="96" height="95" viewBox="0 0 96 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_25_5427)">
        <circle cx="47.5249" cy="43.5" r="43.5" fill="white"/>
      </g>
      <path d="M45.8937 48.3936H49.1562M47.5249 53.2873V59.8123M40.9999 59.8123H54.0499M42.6312 27.189C38.3491 27.202 36.036 27.3293 34.3428 28.5152C33.6414 29.0063 33.0314 29.6163 32.5402 30.3177C31.2124 32.2116 31.2124 34.8868 31.2124 40.2373C31.2124 45.5878 31.2124 48.2615 32.5402 50.157C33.0314 50.8583 33.6414 51.4684 34.3428 51.9595C36.2367 53.2873 38.9119 53.2873 44.2624 53.2873H50.7874C56.1379 53.2873 58.8115 53.2873 60.707 51.9595C61.4084 51.4684 62.0184 50.8583 62.5096 50.157C63.6955 48.4654 63.8227 46.1506 63.8358 41.8686" stroke="#8976FD" strokeWidth="2.50125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M59.7593 30.6882L59.862 30.6148C61.5879 29.3685 62.4508 28.7453 63.1441 29.0471C63.8374 29.3489 63.8374 30.344 63.8374 32.339V33.4548C63.8374 35.4498 63.8374 36.4465 63.1441 36.7466C62.4508 37.0484 61.5879 36.4253 59.862 35.1806L59.7593 35.1056M53.2343 38.6063H54.0499C56.7415 38.6063 58.0872 38.6063 58.9224 37.8755C59.7593 37.143 59.7593 35.9669 59.7593 33.6114V32.184C59.7593 29.8285 59.7593 28.6507 58.9224 27.9183C58.0872 27.1875 56.7415 27.1875 54.0499 27.1875H53.2343C50.5427 27.1875 49.1969 27.1875 48.3617 27.9183C47.5249 28.6507 47.5249 29.8269 47.5249 32.184V33.6114C47.5249 35.9653 47.5249 37.143 48.3617 37.8755C49.1969 38.6063 50.5427 38.6063 53.2343 38.6063Z" stroke="#8976FD" strokeWidth="2.50125" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
        <filter id="filter0_d_25_5427" x="0.0249023" y="0" width="95" height="95" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_25_5427"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_25_5427" result="shape"/>
        </filter>
      </defs>
    </svg>


  );
};

export default FeatureTwo;
