import React from 'react';

const Twitter = () => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25_5568)">
        <path d="M27.9569 7.91156C27.3253 8.75407 26.5619 9.489 25.696 10.088C25.696 10.308 25.696 10.5281 25.696 10.7605C25.7029 14.7572 24.1063 18.5896 21.2644 21.3984C18.4223 24.2071 14.5725 25.7575 10.5779 25.7019C8.2686 25.7095 5.98884 25.1822 3.91723 24.1613C3.80553 24.1124 3.73348 24.0019 3.73389 23.88V23.7455C3.73389 23.57 3.87616 23.4277 4.05167 23.4277C6.32169 23.3528 8.51073 22.5658 10.3091 21.1778C8.25438 21.1364 6.40569 19.9193 5.55491 18.0478C5.51194 17.9455 5.52532 17.8282 5.59021 17.7383C5.65511 17.6484 5.76216 17.5989 5.87267 17.6076C6.49713 17.6703 7.12784 17.6123 7.73033 17.4363C5.46213 16.9656 3.75782 15.0825 3.51391 12.7779C3.50525 12.6673 3.55479 12.5603 3.64465 12.4953C3.73451 12.4304 3.85171 12.417 3.95389 12.46C4.56257 12.7286 5.21965 12.87 5.88489 12.8757C3.8974 11.5713 3.03894 9.09016 3.79501 6.83559C3.87305 6.61651 4.06055 6.45467 4.28861 6.40953C4.51666 6.36441 4.75163 6.44264 4.90716 6.61549C7.58915 9.46986 11.2743 11.1708 15.1855 11.3596C15.0853 10.9598 15.036 10.549 15.0388 10.1369C15.0754 7.97591 16.4125 6.05088 18.4242 5.26301C20.4358 4.47514 22.7238 4.98036 24.2171 6.54214C25.2351 6.34823 26.2192 6.00633 27.1381 5.5273C27.2053 5.48528 27.2908 5.48528 27.3581 5.5273C27.4001 5.59464 27.4001 5.68005 27.3581 5.74739C26.9129 6.76663 26.161 7.6215 25.2071 8.19279C26.0425 8.09591 26.8631 7.89886 27.6514 7.60589C27.7178 7.56071 27.805 7.56071 27.8714 7.60589C27.927 7.63133 27.9685 7.67997 27.985 7.73887C28.0016 7.79776 27.9913 7.86094 27.9569 7.91156Z" fill="#F78900"/>
      </g>
      <defs>
        <clipPath id="clip0_25_5568">
          <rect width="30.875" height="29.375" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default Twitter;
