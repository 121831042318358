import React from 'react';

const CardIconOne = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.5 2.5V7.5H2.5V2.5H27.5ZM27.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V7.5C0 8.16304 0.263392 8.79893 0.732233 9.26777C1.20107 9.73661 1.83696 10 2.5 10H27.5C28.163 10 28.7989 9.73661 29.2678 9.26777C29.7366 8.79893 30 8.16304 30 7.5V2.5C30 1.83696 29.7366 1.20107 29.2678 0.732233C28.7989 0.263392 28.163 0 27.5 0ZM7.5 15V27.5H2.5V15H7.5ZM7.5 12.5H2.5C1.83696 12.5 1.20107 12.7634 0.732233 13.2322C0.263392 13.7011 0 14.337 0 15V27.5C0 28.163 0.263392 28.7989 0.732233 29.2678C1.20107 29.7366 1.83696 30 2.5 30H7.5C8.16304 30 8.79893 29.7366 9.26777 29.2678C9.73661 28.7989 10 28.163 10 27.5V15C10 14.337 9.73661 13.7011 9.26777 13.2322C8.79893 12.7634 8.16304 12.5 7.5 12.5ZM27.5 15V27.5H15V15H27.5ZM27.5 12.5H15C14.337 12.5 13.7011 12.7634 13.2322 13.2322C12.7634 13.7011 12.5 14.337 12.5 15V27.5C12.5 28.163 12.7634 28.7989 13.2322 29.2678C13.7011 29.7366 14.337 30 15 30H27.5C28.163 30 28.7989 29.7366 29.2678 29.2678C29.7366 28.7989 30 28.163 30 27.5V15C30 14.337 29.7366 13.7011 29.2678 13.2322C28.7989 12.7634 28.163 12.5 27.5 12.5Z" fill="#C18E05"/>
    </svg>

  );
};

export default CardIconOne;
