import React from 'react';

const FeatureThree = () => {
  return (
    <svg width="96" height="95" viewBox="0 0 96 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_25_5422)">
        <circle cx="47.5376" cy="43.5" r="43.5" fill="white"/>
      </g>
      <path d="M54.0626 51.6563H44.2751M41.0126 30.45V36.975M36.1188 27.1875V40.2375M31.2251 32.0813V35.3438M45.9063 32.0813V35.3438M34.4876 45.1313C34.4909 51.9042 34.5659 55.3673 36.6376 57.5483C38.7876 59.8125 42.2491 59.8125 49.1688 59.8125H49.9029C56.1131 59.8125 59.2173 59.8125 61.2776 57.961C61.5702 57.6979 61.8415 57.4118 62.0916 57.103C63.8501 54.9351 63.8501 51.6677 63.8501 45.1313C63.8501 38.5948 63.8501 35.3242 62.0916 33.1579C61.8428 32.8508 61.57 32.5639 61.276 32.2998C59.4783 30.6784 56.8781 30.4761 52.1051 30.45H50.8001" stroke="#FCA61F" strokeWidth="2.71875" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
        <filter id="filter0_d_25_5422" x="0.0375977" y="0" width="95" height="95" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_25_5422"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_25_5422" result="shape"/>
        </filter>
      </defs>
    </svg>


  );
};

export default FeatureThree;
