import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './solution.module.scss';
import SideImage from '../../assets/images/image2.svg';
import FillDots from '../../assets/icons/fillDots';
import GreenDots from '../../assets/icons/greenDots';
import Lineone from '../../assets/icons/lineone';
import CardIconOne from '../../assets/icons/cardIconOne';
import CardIconThree from '../../assets/icons/cardIconThree';
import CardIconTwo from '../../assets/icons/cardIconTwo';

const Solution = () => {
  return (
    <Container fluid="xxl" className={`${styles.wrapper} py-5`}>
      <Row>
        <Col className='ps-md-5 pt-md-5'>
          <Row data-aos="fade-up" data-aos-duration="500">
            <p className={`${styles.fontColor} h5 `}>Our Solution</p>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="550">
            <p className='h3 py-md-3'>Feedio</p>
          </Row>
          <p className={`fw-medium lh-lg ${styles.para}`} data-aos="fade-up" data-aos-duration="600">Our AI-powered video creator simplifies content production with advanced automation, enabling seamless video generation for diverse needs. Transforming ideas into polished videos effortlessly, it ensures professional results with minimal effort. Explore the future of video creation with our innovative solution.</p>
          
        
        </Col>
        <Col xl="6">
          <div className={`${styles.sideImage} overflow-hidden`} data-aos="zoom-in" data-aos-duration="500">
            <span className={styles.fillDots}>
              <FillDots color={"#6DEDC3"}/>
            </span>
            <span className={styles.greenDots}>
              <GreenDots color={"#FCA61F"}/>
            </span>
            <span className={styles.line}>
              <Lineone />
            </span>
            <img src={SideImage} alt='img' />
          </div>
        </Col>
      </Row>
      
      <Container>
        <Row>
          <Col xl="4" className='position-relative' data-aos="fade-up" data-aos-duration="600">
            <div className={`${styles.CardIcon} ${styles.one}`}><CardIconOne/></div>
            <p className={`h5 mt-4 fw-semibold ${styles.text}`}>Streamlines the content <br/> creation process</p>
            <p className='fw-medium'>Streamlines content creation for faster, high-quality results, optimizing workflows and tools.</p>
          </Col>
          <Col xl="4" className='position-relative' data-aos="fade-up" data-aos-duration="600">
            <div className={`${styles.CardIcon} ${styles.two}`}><CardIconTwo/></div>
            <p className={`h5 mt-4 fw-semibold ${styles.text}`}>Leverages large language <br/>models AI for content generation</p>
            <p className='fw-medium'>Uses AI-powered language models for efficient, insightful content creation.</p>
          </Col>
          <Col xl="4" className='position-relative' data-aos="fade-up" data-aos-duration="600">
            <div className={`${styles.CardIcon} ${styles.three}`}><CardIconThree/></div>
            <p className={`h5 mt-4 fw-semibold ${styles.text}`}>Combining human expertise <br/> and power of Gen-AI</p>
            <p className='fw-medium'>Empowering creators to craft captivating videos at every stage, from inception to polished perfection.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Solution;
